import * as React from "react";
import styled from "styled-components";

import { device } from "../../utils/media-queries";
import Seo from "../../layout/Seo";
import Layout from "../../layout/Layout";
import Topbar from "../../molecules/topbar";
import H2 from "../../atoms/h2";
import H3 from "../../atoms/h3";

import CasperTigersBanner from "../../images/caspertigers-banner.svg";
import Photo1Src from "../../images/caspertigers-img1.png";
import Photo2Src from "../../images/caspertigers-img2.png";
import BlockchainIcon from "../../images/blockchain-icon.svg";
import FigmaIcon from "../../images/figma-icon.svg";
import JSIcon from "../../images/js-icon.svg";
import RustIcon from "../../images/rust-icon.svg";
import SmartIcon from "../../images/smart-icon.svg";
import PythonIcon from "../../images/python-icon.svg";
import DockerIcon from "../../images/docker-icon.svg";
import NodeIcon from "../../images/node-icon.svg";
import ReactIcon from "../../images/react-icon.svg";
import CloudIcon from "../../images/cloud-icon.svg";

const PageWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0 80px 80px 80px;

  @media ${device.laptop} {
    padding: 0 40px 40px 40px;
  }

  @media ${device.mobile} {
    padding: 0 20px 20px 20px;
  }
`;

const ImageBanner = styled.img`
  width: 100%;
  max-width: 1240px;
  height: auto;
  margin-top: 192px;
  border-radius: 30px;

  @media ${device.tablet} {
    margin-top: 86px;
    border-radius: 15px;
  }

  @media ${device.mobile} {
    margin-top: 66px;
    border-radius: 15px;
  }
`;

const ContentWrapper = styled.div`
  width: 100%;
  max-width: 1030px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 80px;

  @media ${device.tablet} {
    padding: 32px 0px 0px 0px;
  }

  @media ${device.mobile} {
    padding: 16px 0px 0px 0px;
  }
`;

const Text = styled.p`
  font-size: 16px;
  color: ${({ theme }) => theme.colors.neutral.secondary};
  margin-top: 32px;
  max-width: 818px;
  line-height: 24px;
`;

const TextMargin = styled(Text)`
  margin: 40px 0 80px 0;

  @media ${device.tablet} {
    margin: 36px 0 80px 0;
  }
`;

const ImageWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 20px;
  margin: 112px 0;

  @media ${device.desktop} {
    margin: 80px 0;
  }

  @media ${device.laptop} {
    margin: 40px 0;
  }

  @media ${device.tablet} {
    flex-direction: column;
    margin: 40px 0;
  }

  @media ${device.mobile} {
    flex-direction: column;
    margin: 80px 0;
  }
`;

const Photo = styled.img`
  width: 100%;
  height: auto;
  border-radius: 30px;

  @media ${device.desktop} {
    max-width: 410px;
  }

  @media ${device.laptop} {
    max-width: 310px;
  }

  @media ${device.tablet} {
    border-radius: 15px;
    max-width: unset;
  }
`;

const Icon = styled.img`
  width: 60px;
  height: 60px;

  @media ${device.tablet} {
    width: 50px;
    height: 50px;
  }

  @media ${device.mobile} {
    width: 40px;
    height: 40px;
  }
`;

const SubTitle = styled(H3)`
  font-size: 32px;
`;

const TechTitle = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 150%;
  margin-bottom: 32px;
  color: ${({ theme }) => theme.colors.neutral.secondary};
`;

const TechImagesWrapper = styled(ImageWrapper)`
  gap: 24px;
  margin: 0 0 112px 0;

  @media ${device.laptop} {
    gap: 24px 0;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: 1fr 1fr;
    justify-items: center;
    margin: 0 0 80px 0;
  }
`;

const H2Link = styled(H2)`
  text-decoration: none;
  transition: all 0.25s;
  :hover {
    color: ${({ theme }) => theme.colors.primary.main};
  }
`;

const ProjectCasperTigers = () => (
  <Layout>
    <Topbar isSolid />
    <PageWrapper>
      <ImageBanner src={CasperTigersBanner} />
      <ContentWrapper>
        <H2Link as="a" href="https://caspertigers.io/" target="_blank">
          CasperTigers.io
        </H2Link>
        <TextMargin>
          Casper Tigers is a project created to celebrate the successful
          partnership between CasperLabs and China’s Blockchain based Service
          Network (BSN) during the year of Tiger. Each Tiger is unique and was
          created with Casper’s environmentally friendly NFT smart contract
          standards.
        </TextMargin>
        <TechTitle>Technologies Used</TechTitle>
        <TechImagesWrapper>
          <Icon src={BlockchainIcon} />
          <Icon src={FigmaIcon} />
          <Icon src={JSIcon} />
          <Icon src={RustIcon} />
          <Icon src={SmartIcon} />
          <Icon src={PythonIcon} />
          <Icon src={DockerIcon} />
          <Icon src={NodeIcon} />
          <Icon src={ReactIcon} />
          <Icon src={CloudIcon} />
        </TechImagesWrapper>
        <SubTitle isBlue>Challenges</SubTitle>
        <Text>
          Some challenges in the project emerged from the overall complexity of
          the customer’s business. The payments processing industry has a
          complex, rapidly changing ecosystem involving various institutions,
          technologies, and players. <br /> <br /> For example, merchant payment
          transaction processing involves implementing anti-fraud measures;
          thereby, our team added risk-based scorecard functionality to the
          solution. Its purpose is to obtain each merchant’s risk profile and
          assess its progress status as passed, reviewed or failed.
        </Text>
        <ImageWrapper>
          <Photo src={Photo1Src} />
          <Photo src={Photo2Src} />
        </ImageWrapper>
        <SubTitle isBlue>Solution</SubTitle>
        <Text>
          Thanks to Leobit’s BA and technology expertise, 100% of customer’s
          business processes previously done by hand were automated. The data is
          now well-documented and easily managed by software. By digitizing
          processes, our team eliminated inevitable human errors, saved
          customer’s costs for manual work, and, most important, decreased the
          time of deals processing by five times.
          <br />
          <br /> Leobit’s BA specialist thoroughly investigated all operational
          flows and helped to simplify the merchant onboarding process.
          Suggested workflow minimized activities between the start and end
          points of the process. Following BA’s recommendations, our tech team
          eliminated unnecessary tasks and configured most activities to perform
          in a different, more assertive way. As a result, it suppressed the
          merchant onboarding process about three times without affecting its
          efficiency. <br />
          <br />
          The product experienced a low level of process formalization. It led
          to the problem that the same process could be handled differently for
          various payment processors. Our team solved it by arranging
          configurations with input values and predefined templates that can be
          reused in many flows. It helped optimize the overall business process
          and establish a common business logic to manage the variety of
          operations.
        </Text>
      </ContentWrapper>
    </PageWrapper>
  </Layout>
);

export default ProjectCasperTigers;

export const Head = () => <Seo title="Blockbit.es | CasperTigers" />;
